import { OnInit } from '@angular/core';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { DatePipe } from '@angular/common';
var CardComponent = /** @class */ (function () {
    function CardComponent(datepipe) {
        var _this = this;
        this.datepipe = datepipe;
        this.cardUploadDone = false;
        this.cardUploadDate = "";
        this.cardError = "";
        //cardUserBorads: Array<CardUser[]> = [];
        this.cardUserBorads = [];
        this.countElem = 0;
        this.totalPDFPages = 0;
        this.gestInscr = false;
        this.startGestInscrProcess = false;
        this.printCard = false;
        this.processingUserLst = [];
        this.processingUserDtLst = [];
        this.selectedFilter = "";
        this.validInscrCount = 0;
        this.fileTreat = "";
        this.fileInscrFilter = "";
        this.fileInscrSite = "";
        this.fileInscrValid = "";
        this.fileUploadTreat = new FileUploadControl(FileUploadValidators.filesLimit(1));
        this.fileUploadInscrFilter = new FileUploadControl(FileUploadValidators.filesLimit(1));
        this.fileUploadInscrSite = new FileUploadControl(FileUploadValidators.filesLimit(1));
        this.fileUploadInscrValid = new FileUploadControl(FileUploadValidators.filesLimit(1));
        window.onafterprint = function (e) {
            _this.closePrintCard();
        };
    }
    CardComponent.prototype.ngOnInit = function () {
    };
    CardComponent.prototype.reset = function () {
        this.cardUserBorads = [];
    };
    CardComponent.prototype.filterSelect = function (filter) {
        if (this.selectedFilter === filter) {
            this.selectedFilter = "";
        }
        else {
            this.selectedFilter = filter;
        }
    };
    /**
     * Action lors du click sur la checkbox de sélection/déselection d'un utilisateur
     */
    CardComponent.prototype.gestInscrSelectAction = function (idx) {
        var user = this.processingUserLst[idx];
        if (user.processingDt) {
            user.processingDt = "";
            this.validInscrCount--;
        }
        else {
            var dt = this.datepipe.transform(new Date(), 'yyyy/MM/dd');
            user.processingDt = dt;
            this.validInscrCount++;
        }
    };
    /**
     * Démarrage du traitement des inscrits
     */
    CardComponent.prototype.startGestInscr = function () {
        this.processingSourceFileForGestInscr();
        this.startGestInscrProcess = true;
    };
    /**
     * Enregistrement des informations sur les utilisateurs inscrits et enregistrement pour permettre
     * une utilisation ulterieure si nécessaire
     */
    CardComponent.prototype.saveGestInscr = function () {
        var fileData = this.fileUploadInscrSite.valueChanges.getValue()[0];
        var fileName = fileData.name.replace(/\.csv/, '') + '-valides';
        this.downloadFile(fileName);
        //this.gestInscrDiscard();
    };
    /**
     * Exporte les adresses mails des utilisateurs inscrits au club
     */
    CardComponent.prototype.saveMails = function () {
        var fileData = this.fileUploadInscrSite.valueChanges.getValue()[0];
        var fileName = fileData.name.replace(/\.csv/, '') + '-mails';
        this.downloadFile(fileName, true);
    };
    /**
     * Ferme l'initialisation des informations sur les utilisateurs inscrits pour lesquels il faut générer une carte
     */
    CardComponent.prototype.gestInscrDiscard = function () {
        this.fileUploadInscrSite = new FileUploadControl(FileUploadValidators.filesLimit(1));
        this.fileUploadInscrValid = new FileUploadControl(FileUploadValidators.filesLimit(1));
        this.fileInscrSite = "";
        this.fileInscrValid = "";
        this.gestInscr = false;
        this.startGestInscrProcess = false;
        this.processingUserLst = [];
        this.processingUserDtLst = [];
        this.selectedFilter = "";
    };
    /**
     * Initialisation des informations sur les utilisateurs inscrits pour lesquels il faut générer une carte
     */
    CardComponent.prototype.selecteInscr = function () {
        this.processingSourceFileForGestInscr();
        this.gestInscr = true;
    };
    /**
     * Initialisation des infos contenues dans les fichiers uploadés
     * @param event
     * @param type
     */
    CardComponent.prototype.fileUploadInit = function (event, type) {
        var _this = this;
        var fileData;
        var reader = new FileReader();
        if (type === 'Treat') {
            fileData = this.fileUploadTreat.valueChanges.getValue()[0];
        }
        else if (type === 'InscrFilter') {
            fileData = this.fileUploadInscrFilter.valueChanges.getValue()[0];
        }
        else if (type === 'Site') {
            fileData = this.fileUploadInscrSite.valueChanges.getValue()[0];
        }
        else {
            fileData = this.fileUploadInscrValid.valueChanges.getValue()[0];
        }
        reader.readAsText(fileData);
        reader.onload = function (e) {
            if (typeof reader.result === 'string') {
                if (type === 'Treat') {
                    _this.fileTreat = reader.result;
                }
                else if (type === 'InscrFilter') {
                    _this.fileInscrFilter = reader.result;
                }
                else if (type === 'Site') {
                    _this.fileInscrSite = reader.result;
                }
                else {
                    _this.fileInscrValid = reader.result;
                }
            }
        };
    };
    /**
     * Initialisation de l'affichage de la liste des utilisateurs inscrits pour pouvoir définir
     * ceux pour qui il faut générer des cartes
     */
    CardComponent.prototype.processingSourceFileForGestInscr = function () {
        var validInscrMap = new Map();
        // Traitement du fichier des inscrits du site
        var siteInscrLst = this.fileInscrSite.replace(/ , |, | ,/g, ",").split(/\r\n|\n/);
        // Traitement du fichier des inscrits validés
        if (this.fileInscrValid) {
            var lineClean = this.fileInscrValid.replace(/ ; |; | ;/g, ";");
            var validInscrLst = lineClean.split(/\r\n|\n/);
            for (var i = 0; i < validInscrLst.length; i++) {
                var infos = validInscrLst[i].split(';');
                validInscrMap.set(infos[0] + ' ' + infos[1], infos[2]);
            }
        }
        for (var i = 1; i < siteInscrLst.length; i++) {
            var infos = siteInscrLst[i].split('","');
            var typeInscr = infos[0].replace('\"Inscription IPSO Savate Boxe Française (', '').replace(')', '');
            var processingDt = validInscrMap.get(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase());
            if (!processingDt) {
                processingDt = "";
            }
            if (processingDt && !this.processingUserDtLst.includes(processingDt)) {
                this.processingUserDtLst.push(processingDt);
            }
            var ajout = 0;
            if (infos[30].match(/[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/)) {
                ajout = 1;
            }
            var numInscrNumb = 38 + ajout;
            var mail = infos[15];
            var photo = void 0;
            if (typeInscr == 'enfant') {
                if (infos[35].startsWith('data:image/jpeg;base64,') || infos[35].match(/https:\/\/.*\/wp-content\/uploads\/.*.png/)) {
                    photo = infos[35];
                    numInscrNumb = 43;
                }
                else {
                    photo = infos[34];
                    if (infos[36 + ajout].startsWith('{""type"":')) {
                        numInscrNumb = 42;
                    }
                }
            }
            else {
                photo = infos[30 + ajout];
                if (!infos[32 + ajout].startsWith('{""type"":')) {
                    numInscrNumb = 34;
                }
            }
            var numInscrVal = infos[numInscrNumb];
            if (numInscrVal === '') {
                numInscrVal = '5959-' + infos[7].substr(0, 2).toUpperCase() + infos[8].substr(0, 2).toLowerCase() + '-a78b';
            }
            if (processingDt) {
                this.validInscrCount++;
            }
            //console.info(infos);
            //console.info('{"nom": "' + infos[7].toLowerCase() + '", "prenom": "' + infos[8].toLowerCase() + '", "photo": "' + photo + '", "processingDt": "' + processingDt + '", "lock": ' + (processingDt? 'true': 'false') + ', "numInscrVal": "' + numInscrVal + '", "mail": "' + mail + '"}');
            this.processingUserLst.push(JSON.parse('{"nom": "' + infos[7].toLowerCase() + '", "prenom": "' + infos[8].toLowerCase() + '", "photo": "' + photo + '", "processingDt": "' + processingDt + '", "lock": ' + (processingDt ? 'true' : 'false') + ', "numInscrVal": "' + numInscrVal + '", "mail": "' + mail + '"}'));
            this.processingUserLst.sort(function (a, b) { return (a.nom > b.nom) ? 1 : (a.nom === b.nom) ? ((a.prenom > b.prenom) ? 1 : -1) : -1; });
        }
        //console.info('----->',this.processingUserLst);
    };
    /**
     * Valide le texte qui est saisie dans le champ texte devant acceuillir les données à formater au format JSON
     * avant de générer les cartes
     * @param ev
     */
    CardComponent.prototype.validateData = function () {
        try {
            this.cardUserBorads = [];
            var cardInfosLst = [];
            var include = [];
            var exclude = [];
            var validInscrMap = new Map();
            // Traitement du fichier source
            var lines = this.fileTreat.replace(/ , |, | ,/g, ",").split(/\r\n|\n/);
            // Traitement des personnes à inclure
            if (this.fileInscrFilter) {
                var lineClean = this.fileInscrFilter.replace(/ ; |; | ;/g, ";");
                var validInscrLst = lineClean.split(/\r\n|\n/);
                for (var i = 0; i < validInscrLst.length; i++) {
                    var infos = validInscrLst[i].split(';');
                    validInscrMap.set(infos[0] + ' ' + infos[1], infos[2]);
                }
            }
            var cardBoardContent = [];
            var elemCount = 0;
            for (var i = 1; i < lines.length; i++) {
                var infos = lines[i].split('","');
                var processingDt = validInscrMap.get(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase());
                if (!processingDt) {
                    processingDt = "";
                }
                if (processingDt && !this.processingUserDtLst.includes(processingDt)) {
                    this.processingUserDtLst.push(processingDt);
                }
                //console.info('-' + i + '--infos->', infos);
                var typeInscr = infos[0].replace('\"Inscription IPSO Savate Boxe Française (', '').replace(')', '');
                var cardInfos = '';
                if (typeInscr == 'enfant') {
                    //console.info('----->' + infos[7].toLowerCase() + ' ' + infos[8].toLowerCase(), !include.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase()));
                    if (include.length > 0 && !include.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase())) {
                        continue;
                    }
                    typeInscr = this.strUcFirst(typeInscr);
                    var numInscrNumb = 42;
                    if (!infos[36].startsWith('{""type"":')) {
                        numInscrNumb = 38;
                    }
                    var photo = infos[34];
                    if (infos[35].startsWith('data:image/jpeg;base64,') || infos[35].match(/https:\/\/.*\/wp-content\/uploads\/.*.png/)) {
                        photo = infos[35];
                        numInscrNumb = 43;
                    }
                    var numInscrVal = infos[numInscrNumb];
                    cardInfos = '{"type": "' + typeInscr +
                        '", "nom": "' + this.strUcFirst(infos[7].toLowerCase()) +
                        '", "prenom": "' + this.strUcFirst(infos[8].toLowerCase()) +
                        '", "numInscr": "' + numInscrVal +
                        '", "photo": "' + photo +
                        '", "processingDt": "' + processingDt +
                        '"}';
                    //console.info('-' + i + '--cardInfos-->', cardInfos);
                }
                else {
                    //console.info('----->' + infos[7].toLowerCase() + ' ' + infos[8].toLowerCase(), !include.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase()));
                    if (include.length > 0 && !include.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase())) {
                        continue;
                    }
                    if (exclude.length > 0 && exclude.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase())) {
                        continue;
                    }
                    var ajout = 0;
                    if (infos[30].match(/[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/)) {
                        ajout = 1;
                    }
                    var numInscrNumb = 38 + ajout;
                    if (!infos[32 + ajout].startsWith('{""type"":')) {
                        numInscrNumb = 34;
                    }
                    var numInscrVal = infos[numInscrNumb];
                    if (numInscrVal === '') {
                        numInscrVal = '5959-' + infos[7].substr(0, 2).toUpperCase() + infos[8].substr(0, 2).toLowerCase() + '-a78b';
                    }
                    cardInfos = '{"type": "' + typeInscr +
                        '", "nom": "' + this.strUcFirst(infos[7].toLowerCase()) +
                        '", "prenom": "' + this.strUcFirst(infos[8].toLowerCase()) +
                        '", "numInscr": "' + numInscrVal +
                        '", "photo": "' + infos[30 + ajout] +
                        '", "processingDt": "' + processingDt +
                        '"}';
                    //console.info('-' + i + '--cardInfos-->', cardInfos);
                }
                /*if (elemCount === 8) {
                  this.cardUserBorads.push(JSON.parse(JSON.stringify(cardBoardContent)));
                  cardBoardContent = [];
                  elemCount = 0;
                }
                cardBoardContent.push(JSON.parse(cardInfos));*/
                this.cardUserBorads.push(JSON.parse(cardInfos));
                elemCount++;
            }
            /*if (cardBoardContent.length > 0) {
              this.cardUserBorads.push(JSON.parse(JSON.stringify(cardBoardContent)));
            }*/
            //console.info('---->',this.cardUserBorads);
            this.cardUserBorads.sort(function (a, b) { return (a.nom > b.nom) ? 1 : (a.nom === b.nom) ? ((a.prenom > b.prenom) ? 1 : -1) : -1; });
            this.totalPDFPages = this.cardUserBorads.length;
        }
        catch (e) {
            console.info('---ERROR--', e);
            this.cardError = "La valeur saisie dans le champ est incorrecte !";
        }
    };
    /**
     * Passe la première lettre en majuscule
     * @param a
     */
    CardComponent.prototype.strUcFirst = function (a) {
        return (a + '').charAt(0).toUpperCase() + a.substr(1);
    };
    /**
     * Transforme la liste des utilisateurs qui ont validé leur inscription en fichier csv
     * @param objArray
     */
    CardComponent.prototype.ConvertInscrValidToCSV = function () {
        var str = '';
        for (var i = 0; i < this.processingUserLst.length; i++) {
            var user = this.processingUserLst[i];
            if (user.processingDt !== '') {
                str += user.nom + ";" + user.prenom + ";" + user.processingDt + "\r\n";
            }
        }
        return str;
    };
    /**
     * Création d'un fichier CSV contenant les Mails des utilisateurs dont l'inscription au club est validée
     */
    CardComponent.prototype.ConvertInscrValidMailToCSV = function () {
        var str = '';
        for (var i = 0; i < this.processingUserLst.length; i++) {
            var user = this.processingUserLst[i];
            if (user.processingDt !== '') {
                str += user.nom + ";" + user.prenom + ";" + user.mail.toLowerCase() + "\r\n";
            }
        }
        return str;
    };
    /**
     * Lance le Téléchargement du fichier généré
     * @param filename
     */
    CardComponent.prototype.downloadFile = function (filename, exportMail) {
        if (filename === void 0) { filename = 'data'; }
        if (exportMail === void 0) { exportMail = false; }
        var csvData = '';
        if (exportMail) {
            csvData = this.ConvertInscrValidMailToCSV();
        }
        else {
            csvData = this.ConvertInscrValidToCSV();
        }
        var blob = new Blob(['\ufeff' + csvData], {
            type: 'text/csv;charset=utf-8;'
        });
        var dwldLink = document.createElement("a");
        var url = URL.createObjectURL(blob);
        var isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1;
        navigator.userAgent.indexOf('Chrome') == -1;
        //if Safari open in new window to save file with random filename. 
        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    };
    CardComponent.prototype.closePrintCard = function () {
        this.printCard = false;
    };
    CardComponent.prototype.startPrintCard = function () {
        this.printCard = true;
        this.test();
    };
    CardComponent.prototype.test = function () {
        this.waitForOneSecond().then(function (value) {
            console.log(value);
            window.print();
        });
    };
    CardComponent.prototype.waitForOneSecond = function () {
        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve("I promise to return after one second!");
            }, 1000);
        });
    };
    return CardComponent;
}());
export { CardComponent };
